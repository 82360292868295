import React, { useState } from 'react';
import PropTypes from 'prop-types';
import YoutubeCard from './YoutubeCard';
import { extractVideoInfo } from './YoutubeUtils';

export default function YoutubeVideo( { videos } ) {
  const [ latestVideo ] = videos;
  const [ mainVideo, setMainVideo ] = useState( latestVideo );
  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
      <div className="lg:col-span-2">
        <YoutubeCard { ...extractVideoInfo( mainVideo ) } />
      </div>
      <div className="flex flex-col gap-2 lg:gap-8 overflow-y-scroll scrollbar-hide max-h-[36rem]">
        {
          videos.map( ( video ) => {
            const videoInfo = extractVideoInfo( video );
            return (
              <YoutubeCard
                key={ videoInfo.id }
                { ...videoInfo }
                horizontal
                onClick={ () => setMainVideo( video ) }
              />
            );
          } )
        }
      </div>
    </div>
  );
}
YoutubeVideo.propTypes = {
  videos: PropTypes.array,
};
