import React from 'react';

export default function YoutubeChannelLink( { children, className } ) {
  const channelLink = 'https://www.youtube.com/channel/UCl4T9fsSMrs7C1Uvf5tXGhg';
  return (
    <a href={ channelLink } target="_blank" rel="noopener noreferrer" className={ className }>
      {children}
    </a>
  );
}
