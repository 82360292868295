import React from 'react';
import Image from 'next/image';
import dayjs from '@dayjs';
import YoutubeIframe from './YoutubeIframe';
import YoutubeChannelLink from './YoutubeChannelLink';

export default function YoutubeCard( { title, channel, videoId, thumbnail, publishedAt, horizontal, onClick } ) {
  const timeFromNow = dayjs( publishedAt ).fromNow();

  if ( horizontal ) {
    return (
      <div className="grid grid-cols-3 gap-2 shadow py-4 first:pt-0">
        <div className="cursor-pointer mx-auto" onClick={ onClick }>
          <Image
            src={ thumbnail }
            width={ 128 }
            height={ 96 }
            placeholder="blur"
            blurDataURL="images/placeholder.png"
            alt={ title }
          />
        </div>
        <div className="col-span-2 flex flex-col justify-evenly">
          <h3
            title={ title }
            className="cursor-pointer text-md font-bold line-clamp-2"
            role="presentation"
            onClick={ onClick }
          >
            {title}
          </h3>
          <p className="text-base mt-1 flex justify-between select-none text-gray-900">
            <YoutubeChannelLink>
              <strong>
                {channel}
              </strong>
            </YoutubeChannelLink>
            <span className="text-sm text-gray-800">{timeFromNow}</span>
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <YoutubeIframe title={ title } videoId={ videoId } />
      <div className="flex flex-col mt-2">
        <h3 title={ title } className="cursor-pointer mt-1 text-lg font-bold line-clamp-2">
          {title}
        </h3>
        <p className="text-md mt-1 flex justify-between select-none text-gray-900">
          <YoutubeChannelLink>
            <strong>
              {channel}
            </strong>
          </YoutubeChannelLink>
          <span className="text-sm text-gray-800">{timeFromNow}</span>
        </p>
      </div>
    </div>
  );
}
