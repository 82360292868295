import React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import YoutubeVideo from './YoutubeVideo';
import YoutubeChannelLink from './YoutubeChannelLink';

export default function YoutubeSection( { data: { info, statistics, videos } } ) {
  const subscribers = `${ statistics.subscriberCount } subscribers`;
  return (
    <>
      <div className="flex select-none">
        <div className="m-4 w-32 h-32 rounded-full overflow-hidden">
          <Image
            src={ info.thumbnails.high.url }
            placeholder="blur"
            blurDataURL="images/placeholder.png"
            alt="nghien-fifa channel"
            width={ 128 }
            height={ 128 }
          />
        </div>
        <div className="flex flex-col justify-center px-2">
          <h1 className="text-2xl font-bold">
            <YoutubeChannelLink>
              {info.title}
            </YoutubeChannelLink>
          </h1>
          <span className="text-base text-gray-900">
            {subscribers}
          </span>
        </div>
      </div>

      <YoutubeVideo videos={ videos } />
    </>
  );
}
YoutubeSection.propTypes = {
  data: PropTypes.shape( {
    info: PropTypes.object,
    statistics: PropTypes.object,
    videos: PropTypes.array,
  } ),
};
